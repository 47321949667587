import { createApp } from 'vue';
import megaMenu from '@/components/mega_menu/index.vue';
import megaMenuMobile from '@/components/mega_menu_mobile/index.vue';
import {tns} from 'tiny-slider';
// import simpleParallax from "simple-parallax-js/vanilla";
// import LocomotiveScroll from 'locomotive-scroll';
import Rails from "@rails/ujs"
import Painter from "@/stuff/char_painter.js";
import IMask from 'imask';


// const map_customization = [{tags: {any: ['road']},elements: 'geometry',stylers: [{color: '#4E4E4E'}]},
//     {tags: {any: ['water']},elements: 'geometry',stylers: [{color: '#000000'}]},
//     {tags: {any: ['landscape', 'admin', 'land', 'transit']},elements: 'geometry',stylers: [{color: '#212121'}]},
//     {tags: {any: ['building']},elements: 'geometry',stylers: [{color: '#757474'}]}]

import {params} from '../stuff/map_params.js'

if (document.querySelector('#map')) {
    initMap();
}


async function initMap() {
    await ymaps3.ready;
    ymaps3.import.registerCdn('https://cdn.jsdelivr.net/npm/{package}', '@yandex/ymaps3-default-ui-theme@0.0.2')
    const {YMap, YMapDefaultSchemeLayer,YMapDefaultFeaturesLayer,YMapCollection,YMapControls,YMapMarker} = ymaps3;
    const {YMapZoomControl} = await ymaps3.import('@yandex/ymaps3-controls@0.0.1');
    const {YMapDefaultMarker} = await ymaps3.import('@yandex/ymaps3-default-ui-theme');

    // const marker = {coordinates: [37.536758,55.749554],title: 'asdasd', description: 'cxvcxvxcv'}

    const marker = new YMapDefaultMarker({
        coordinates: [37.536758,55.749554],
        iconName: 'auto',
        title: 'Terminal A',
        subtitle: '',
        color: {day: '#000000', night: '#000000'},
        size: 'normal'
    });

    const map = new YMap(
        document.getElementById('mapp'),
        {
            location: {
                center: [37.536758,55.749554],
                zoom: 15
            },
            behaviors: ['drag', 'pinchZoom', 'mouseTilt']
        },
    );

    const collection = new YMapCollection();
    map.addChild(new YMapDefaultSchemeLayer({customization:params}))
    // map.addChild(new YMapDefaultSchemeLayer({}))
    map.addChild(new YMapDefaultFeaturesLayer({zIndex: 1800}))

    map.addChild(collection);
    map.addChild(new YMapControls({position: 'right'}).addChild(new YMapZoomControl({})));
    collection.addChild(marker);
}


document.addEventListener("DOMContentLoaded", (event) => {
    for (let i = 1; i < 11; i++) {
        Painter.start(`#animate${i > 1 ? i : ''}`)
    }
    // Painter.start('#animate')
    // Painter.start('#animate2')

    const mega_menu = document.getElementById('v-mega-menu');
    if (mega_menu) {
        const app = createApp(megaMenu,{});
        app.mount(mega_menu);
    }

    const mega_menu_mobile = document.getElementById('v-mega-menu-mobile');
    if (mega_menu_mobile) {
        const app = createApp(megaMenuMobile,{});
        app.mount(mega_menu_mobile);
    }

})



document.addEventListener("DOMContentLoaded", (event) => {
    //мобильное меню
    const m_togglers =  document.querySelectorAll('.mobile-menu-toggler')
    m_togglers.forEach( el => {
        el.addEventListener('click', () => {
            const menu = document.querySelector('.mobile-menu')
            if (menu) {
                if (menu.classList.contains('opened')) {
                    menu.classList.remove('opened')
                } else {
                    menu.classList.add('opened')
                }
            }
        })
    })
})



const breakpoints = {
    a: { 1441: { spaceBetween: 40, slidesPerView: 2 }, 980: { spaceBetween: 30, slidesPerView: 2 }, 430: { spaceBetween: 20, slidesPerView: 1 }, 0: { spaceBetween: 20, slidesPerView: 1 } },
    b: { 1441: { spaceBetween: 20, slidesPerView: 3 }, 980: { spaceBetween: 20, slidesPerView: 3 }, 430: { spaceBetween: 20, slidesPerView: 1 }, 0: { spaceBetween: 20, slidesPerView: 1 } },
    c: { 1441: { spaceBetween: 40, slidesPerView: 3 }, 980: { spaceBetween: 30, slidesPerView: 3 }, 430: { spaceBetween: 20, slidesPerView: 1 }, 0: { spaceBetween: 20, slidesPerView: 1 } },
    d: { 1441: { spaceBetween: 0, slidesPerView: 1 },  980: { spaceBetween: 0, slidesPerView: 1 }, 430: { spaceBetween: 0, slidesPerView: 1 }, 0: { spaceBetween: 0, slidesPerView: 1 } },
}
document.addEventListener("DOMContentLoaded", (event) => {



    if (document.querySelector('header')) {
        if (!document.querySelector('header').classList.contains('skip-scroll')) {
            window.addEventListener('scroll', () => {
                document.querySelector('header').classList.toggle('header-scrolled', window.scrollY > window.innerHeight);
            });
            if (document.querySelector('.mobile-header')) {
                window.addEventListener('scroll', () => {
                    document.querySelector('.mobile-header').classList.toggle('mobile-header-scrolled', window.scrollY > window.innerHeight);
                });
            }
        }

    }

    if (document.querySelector('.support-btn')) {
        window.addEventListener('scroll', () => {
            document.querySelector('.support-btn').classList.toggle('support-btn-scrolled', window.scrollY > 80);
        });
    }



    // // SWIPER
    //
    //
    // const swipers = document.querySelectorAll(".swiper")
    // swipers.forEach((swiper) => {
    //     let slider = new Swiper(swiper, {
    //         // Optional parameters
    //         direction: 'horizontal',
    //         pagination: {
    //             el: '.swiper-pagination',
    //             type: 'bullets',
    //
    //         },
    //         breakpoints: breakpoints[swiper.dataset.breakpoint]
    //     });
    // });


    //  FAQ

    document.querySelectorAll(".question-header").forEach((el) => {
        el.addEventListener('click', (event) => {
            let answer = event.target.closest('.question').querySelector('.answer')
            let shevron = event.target.closest('.question').querySelector('.faq-shevron')
            answer.classList.toggle('hidden')
            shevron.classList.toggle('rotated')
        })

    })

// /POPUP

    const popupform = document.querySelector(".popup-form");
    // const connectbtn = document.querySelector('.connect')
    const applicationbtn = document.querySelector(".header-btn1");
    const individualorder = document.querySelector('.individual-order')
    const popupclose = document.querySelector(".popup-close");

    if (popupform) {
        applicationbtn.addEventListener("click", () => popupform.classList.remove("hidden"));
        if (individualorder) {
            individualorder.addEventListener("click", () => popupform.classList.remove("hidden"));
        }

        popupclose.addEventListener("click", () => {
            popupform.classList.add("hidden")
            document.querySelector('#msg-frm').classList.add('hidden')
            document.querySelector('.popup-wrapper .text-wrapper').classList.remove('hidden')
            document.querySelector('#request-form').reset()
            document.querySelector('#request-form').classList.remove('hidden')
        })
    }

    document.querySelectorAll('.popup-opener').forEach(node => {
        node.addEventListener("click", () => popupform.classList.remove("hidden"));
    })

})



document.addEventListener("DOMContentLoaded", (event) => {
    document.querySelectorAll('.my-slider-a').forEach(node => {
        tns({
            container: node,
            'mouseDrag': true,
            'autoWidth': true,
            'loop': false,
            'controls': false,
            'nav': false,
            'swipeAngle': 40,
            responsive: {
                    0: {'items': 1.3,"gutter": 10, 'edgePadding': 10},
                430: {'items': 1.3,"gutter": 20, 'edgePadding': 10},
                980: {'items': 2.3,"gutter": 30, 'edgePadding': 20},
                1441: {'items': 2.3,"gutter": 40, 'edgePadding': 60},
            }
        });
    })
    document.querySelectorAll('.my-slider-b').forEach(node => {
        tns({
            container: node,
            'mouseDrag': true,
            // 'autoWidth': true,
            'loop': false,
            'controls': true,
            'nav': true,
            'swipeAngle': 40,
            'items': 3,
            "slideBy": "page",
            controlsPosition: 'bottom',
            navPosition: 'bottom',
            // controlsText: ['<', '>'],
            controlsContainer: '#customize-controls',
            responsive: {
                0: {'items': 1,"gutter": 0},
                430: {'items': 1,"gutter": 0},
                980: {'items': 2,"gutter": 40},
                1441: {'items': 3,"gutter": 80},
            }
        });
    })


    let connector = document.querySelector('#connector')
    if (connector) {
        connector.addEventListener('click',(event) => {
            connector.classList.add('transparent')
            document.querySelector('.connection-list').classList.remove('transparent')
        })
    }


})

document.addEventListener('DOMContentLoaded',()=>{
    // if (document.querySelector('.welcome-text')) {
    //     document.querySelector('.welcome-text').addEventListener('click',()=>{
    //         document.querySelector('.mobile-menu').classList.add('opened')
    //         document.querySelector('.mobile-menu-opener').click()
    //     })
    // }

    document.querySelectorAll('.catalog-opener').forEach((el)=>{
        el.addEventListener('click',()=>{
            if (document.querySelector('.btn-cars').checkVisibility()) {
                document.querySelector('.btn-cars').click()
            } else {
                document.querySelector('.mobile-menu').classList.add('opened')
                document.querySelector('.mobile-menu-opener').click()
            }
        })
    })

    document.querySelectorAll('.goto').forEach(el => {
        el.addEventListener('click',()=>{
            const target = el.dataset.target
            if (target) {
                const offset = -130;
                const element = document.getElementById(target);
                const y = element.getBoundingClientRect().top + window.scrollY + offset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        })
    })

    const url = new URL(window.location.href);
    if (url.hash.length > 0) {
        const offset = -130;
        const element = document.querySelector(url.hash);
        if (element) {
            setTimeout(() => {
                const y = element.getBoundingClientRect().top + window.scrollY + offset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }, "500");

        }
    }

    if (document.getElementById('cookie-closer')) {
        document.getElementById('cookie-closer').addEventListener('click',()=>{
            document.querySelector('.cookies-warning-container').remove();
            document.cookie = "hideCookieWarning=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        })
    }

    document.querySelectorAll('.phone-mask').forEach(el => {
        let maskOptions = {
            mask: '+{7} (000) 000-00-00'
        };
        IMask(el, maskOptions);
    })

    // document.querySelectorAll('.catalog-link').forEach(el => {
    //     el.addEventListener('click',(event)=>{
    //         window.quick_catalog = event.target.closest('.catalog-link').dataset.catalog
    //         if (document.querySelector('.btn-cars').checkVisibility()) {
    //             document.querySelector('.btn-cars').click()
    //         } else {
    //             document.querySelector('.mobile-menu').classList.add('opened')
    //             document.querySelector('.mobile-menu-opener').click()
    //         }
    //     })
    // })



})

Rails.start()